
import AuthService from "@/auth/AuthService";
import OJ9010S from "@/service/OJ9010Service";
import ZFunc from  "@/service/ZFunc"
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ9010F extends Vue {
    // クラス定義
    AuthS: AuthService = new AuthService();
    OJ9010S: OJ9010S = new OJ9010S();
    ZF: ZFunc = new ZFunc();

    //ログイン情報
    mAREAKY: any = 0;
    mTOKUKY: any = 0;
    mUSERKY: any = 0;

    //検索フィールド変数
    mRefTOROFR: any = "";
    mRefTOROTO: any = "";

    mChikuList: any[] = [];
    mKENMSG: any = "";

    // 一覧フィールド変数
    mDgList: any[] = [];            // データグリッドソース
    loadingFlg: boolean = false;    // ロード中フラグ

    // 変数定義
    $cookies: any;    // cookie


    mounted(): void {
        this.fncInit();
    }

    // 初期表示処理
    fncInit(): void {
        this.mUSERKY = this.$cookies.get("USERKY");
        this.fncClear();
        this.fncGetUserInfo();
        //初期表示用データ取得
        let wParam = { 
            AREAKY:  this.mAREAKY
        };
        this.OJ9010S.fncInit(wParam).then((data: any) => {
            this.mRefTOROFR = data.TOROFR;
            this.mRefTOROTO = data.TOROTO;
            this.fncGetList();
        });
    }

    // 初期化処理
    fncClear(): void {
        this.mRefTOROFR = "";
        this.mRefTOROTO = "";
    }

    //ログイン情報取得
    fncGetUserInfo():void{
        let wParam = { 
            USERKY: this.mUSERKY
        };
        this.OJ9010S.fncGetUserInfo(wParam).then((data: any) => {
            this.mAREAKY = data.AREAKY;
            this.mTOKUKY = data.TOKUKY;
        });
    }

    // おくやみ取得処理
    fncGetList(): void {
        let wParam = { 
            TOKUKY: this.mTOKUKY
            ,TOROFR: this.mRefTOROFR
            ,TOROTO: this.mRefTOROTO
        };
        this.loadingFlg = true;
        this.OJ9010S.fncGetList(wParam).then((data: any) => {
            this.mDgList = data.dgList;
            this.mKENMSG = data.kenMsg;
        }).finally(() => {this.loadingFlg = false;});
    }

    // 出力ダイアログ表示
    fncShowExport(): void {
        // 画面を呼び出す
        const zZ9013F: any = this.$refs["OJ9013F"];
        //ダイアログを表示
        zZ9013F.fncShow(
            {
                TOKUKY: this.mTOKUKY
                ,TOROFR: this.mRefTOROFR
                ,TOROTO: this.mRefTOROTO
            },
            () => {
                this.fncGetList();
            }
        );
    }
}
